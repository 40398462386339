<template>
  <div
    v-if="feedbackCountTotal || otherFeedbackCountTotal"
    class="feedbackManager"
    :style="(otherFeedbackCountTotal > 0) ? 'height: 35px' : 'height: 20px'"
  >
    <div class="feedbackCount">
      ✔️: <span>{{ greenCheckCount }}</span>&nbsp;&nbsp;|
      ❌: <span>{{ redXCount }}</span>&nbsp;&nbsp;|
      😵: <span>{{ inTheMiddleCount }}</span>
      <div
        v-if="(otherFeedbackCountTotal > 0)"
      >
        👍: <span>{{ thumbsUpCount }}</span>&nbsp;&nbsp;|
        👎: <span>{{ thumbsDownCount }}</span>&nbsp;&nbsp;|
        <i class="bx bxs-right-arrow right-arrow" />: <span>{{ fasterCount }}</span>&nbsp;&nbsp;|
        <i class="bx bxs-left-arrow left-arrow" />: <span>{{ slowerCount }}</span>
      </div>
    </div>
    <div
      class="resetFeedback"
      @click="resetFeedback"
    >
      Reset
    </div>
  </div>
</template>

<style>

div.feedbackManager {
  display: flex;
  height: 20px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(208,208,208,1) 0%, rgba(255,255,255,1) 26%);
  border-top:1px solid white;
  justify-content: space-between;
  padding: 5px;
  align-items: flex-end;
  position: relative;
}

div.feedbackCount {
  padding: 0px
}

div.resetFeedback {
  text-decoration: underline;
  cursor: pointer;
}

</style>

<script lang="ts">
import { getApp } from '../models/App'
import { User } from '../models/User'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'FeedbackManager',
  setup () {
    const app = getApp()

    const resetFeedback = () => {
      app.restrictedSend('[reset]')
    }

    // Calculate the number of users giving each feedback
    const greenCheckCount = computed(() => User.all().filter(u => u.feedback === '✔️' && u.visible).length)
    const redXCount = computed(() => User.all().filter(u => u.feedback === '❌' && u.visible).length)
    const inTheMiddleCount = computed(() => User.all().filter(u => u.feedback === '😵' && u.visible).length)
    const thumbsUpCount = computed(() => User.all().filter(u => u.feedback === '👍' && u.visible).length)
    const thumbsDownCount = computed(() => User.all().filter(u => u.feedback === '👎' && u.visible).length)
    const fasterCount = computed(() => User.all().filter(u => u.feedback === '<i class=\'bx bxs-right-arrow right-arrow\' />' && u.visible).length)
    const slowerCount = computed(() => User.all().filter(u => u.feedback === '<i class=\'bx bxs-left-arrow left-arrow\' />' && u.visible).length)

    // Count the total number of responses given.
    const feedbackCountTotal = computed(() => {
      return greenCheckCount.value + redXCount.value + inTheMiddleCount.value
    })

    // Count the total number of "other" responses given.
    const otherFeedbackCountTotal = computed(() => {
      return thumbsUpCount.value + thumbsDownCount.value + fasterCount.value + slowerCount.value
    })

    return {
      app,
      resetFeedback,
      greenCheckCount,
      redXCount,
      inTheMiddleCount,
      thumbsUpCount,
      thumbsDownCount,
      fasterCount,
      slowerCount,
      feedbackCountTotal,
      otherFeedbackCountTotal
    }
  }
})
</script>
